



























import { Component, Prop, Vue } from "vue-property-decorator";
import AppAlert from "@/components/core/AppAlert.vue";
import { Notification } from "@/utils/notification";

@Component({ components: { AppAlert } })
export default class AppListGroup extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly border!: boolean;

  @Prop({ type: Object, required: false })
  readonly notification!: Notification;

  get hasHeader(): boolean {
    return !!this.$slots["listGroupHeader"];
  }

  hasRecords(): boolean {
    return !!this.$slots["default"] && !!this.$slots.default[0];
  }
}
