













import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AppListGroupItem extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isListHeader!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isSelectable!: boolean;
}
